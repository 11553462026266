import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import MeetingCalendarNotificationModalProps from './meetingCalendarNotificationModalProps';
import Button from '../buttons/button';
import Modal from '../containers/modal';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';
import RichTextField from '../fields/richTextField';
import FieldLoadingPlaceholder from '../fields/fieldLoadingPlaceholder';
import Field from '../fields/field';

class MeetingCalendarNotificationModal extends ObservingComponent<MeetingCalendarNotificationModalProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.meetingCalendarNotificationModal.observationProvider);
    }

    public render({ meetingCalendarNotificationModal }: MeetingCalendarNotificationModalProps): JSX.Element {
        return (
            <div>
                {!!meetingCalendarNotificationModal.isOpen &&
                    <Modal
                        heading={meetingCalendarNotificationModal.title}
                        close={meetingCalendarNotificationModal.hide}
                        headerButtons={[]}
                        footerLeftButtons={[]}
                        footerRightButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                button={meetingCalendarNotificationModal.closeButton} />,
                            <Button
                                color={ButtonColors.Primary}
                                button={meetingCalendarNotificationModal.sendButton} />
                        ]}>
                        <div>
                            <Alert
                                className="mt-1"
                                alert={meetingCalendarNotificationModal.alert} />

                            {meetingCalendarNotificationModal.isLoading &&
                                <div>
                                    <h4 class="section-header text-sm">Attendees</h4>
                                    <FieldLoadingPlaceholder />
                                    <FieldLoadingPlaceholder />


                                    <h4 class="section-header text-sm">Guests</h4>
                                    <FieldLoadingPlaceholder />
                                    <FieldLoadingPlaceholder />

                                    <h4 class="section-header text-sm">Reply To</h4>
                                    <FieldLoadingPlaceholder />
                                    <FieldLoadingPlaceholder />

                                    <h4 class="section-header text-sm">Email</h4>
                                    <FieldLoadingPlaceholder />
                                    <FieldLoadingPlaceholder />
                                    <FieldLoadingPlaceholder />
                                </div>
                            }

                            {!meetingCalendarNotificationModal.isLoading &&
                                <div class="form-container">
                                    <h4 class="section-header text-sm mb-p5">Attendees</h4>
                                    {meetingCalendarNotificationModal.data.isIndividualAttendees && meetingCalendarNotificationModal.data.attendees.map((attendee, index) => {
                                        return (
                                            <div class="flex mb-p5">
                                                <Field
                                                    className="v-center label-right"
                                                    field={meetingCalendarNotificationModal.attendeeCheckBoxes[index]} />

                                                <Field
                                                    className="v-center no-label input-sm ml-1"
                                                    field={meetingCalendarNotificationModal.attendeeEmailFields[index]} />
                                            </div>
                                    )})}

                                    {!meetingCalendarNotificationModal.data.isIndividualAttendees && 
                                        <div class="mb-p5">
                                            <Field 
                                                className="label-right"
                                                field={meetingCalendarNotificationModal.multipleAttendeeEmailField} />
                                        </div>
                                    }

                                    <h4 class="section-header text-sm mb-p5">Guests</h4>
                                    {meetingCalendarNotificationModal.data.guests.map((guest, index) => {
                                        return (
                                            <div class="flex mb-p5">
                                                <Field
                                                    className="v-center label-right"
                                                    field={meetingCalendarNotificationModal.guestCheckBoxes[index]} />

                                                <Field
                                                    className="v-center no-label input-sm ml-1"
                                                    field={meetingCalendarNotificationModal.guestEmailFields[index]} />
                                            </div>
                                    )})}

                                    <h4 class="section-header text-sm mb-p5">Reply To</h4>
                                    <div class="flex">
                                        <div class="flex">
                                            <Field 
                                                className="v-center inline-label input-sm"
                                                field={meetingCalendarNotificationModal.replyToNameField} />
                                        </div>
                                        <div class="flex ml-1 mr-auto">
                                            <Field     
                                                className="v-center inline-label input-sm"
                                                field={meetingCalendarNotificationModal.replyToEmailField} />
                                        </div>
                                    </div>

                                    <h4 class="section-header text-sm">Email</h4>

                                    <Field field={meetingCalendarNotificationModal.ccEmailField} />
                                    <Field field={meetingCalendarNotificationModal.subjectField} />
                                    <RichTextField field={meetingCalendarNotificationModal.emailTemplateField} />
                                </div>
                            }
                        </div>
                    </Modal>
                }
            </div>
        );
    }
}

export default MeetingCalendarNotificationModal;
