"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var api_1 = require("../../api");
var alert_1 = require("../alert");
var button_1 = require("../button");
var button_2 = require("../button/button");
var dataList_1 = require("../dataList");
var fields_1 = require("../fields");
var icons_1 = require("../icons");
var MeetingNotesForm = /** @class */ (function () {
    function MeetingNotesForm(api, currentUserManager) {
        var _this = this;
        this.api = api;
        this.currentUserManager = currentUserManager;
        this.editNoteForms = [];
        this.hideDeleteNoteConfirmation = function () {
            _this.noteIdToDelete = null;
            _this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.DeleteNoteClicked);
        };
        this.deleteNote = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.delete("".concat(api_1.EndpointUrls.Meetings, "/Notes"), this.noteIdToDelete)];
                    case 1:
                        _a.sent();
                        this.deleteConfirmationPrompt.deleteButton.setProcessing(false);
                        this.deleteConfirmationPrompt.hide();
                        this.noteIdToDelete = null;
                        this.loadMeeting(this._meetingId);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.deleteConfirmationPrompt.deleteButton.setProcessing(false);
                        this.noteIdToDelete = null;
                        this.hideDeleteNoteConfirmation();
                        this.alert.show({
                            alertType: alert_1.AlertType.Error,
                            message: 'An error occurred trying to delete your meeting note'
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.show = function () {
            _this._isVisible = true;
            _this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.Shown);
        };
        this.hide = function () {
            _this._isVisible = false;
            _this.alert.hide();
            _this._meetingNotes = null;
            _this._meetingId = null;
            _this.newNoteTextField.clearValue();
            _this.newTitleTextField.clearValue();
            _this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.Hidden);
        };
        this.loadMeeting = function (meetingId) { return __awaiter(_this, void 0, void 0, function () {
            var response, _a, meetingNoteListItems, err_2;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._meetingId = meetingId;
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.Loading);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.api.get("".concat(api_1.EndpointUrls.Meetings, "/").concat(this._meetingId, "/Notes"))];
                    case 2:
                        response = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.api.getMeetingNotesDisclaimer()];
                    case 3:
                        _a._meetingNotesDisclaimer = (_b.sent()).disclaimerText;
                        this._meetingNotes = response;
                        this.editNoteForms = [];
                        meetingNoteListItems = this.meetingNotes.map(function (meetingNote) {
                            var listItem = meetingNote;
                            listItem.isDeletable = listItem.userId === _this.currentUserManager.userId;
                            listItem.isEditable = listItem.userId === _this.currentUserManager.userId;
                            return listItem;
                        });
                        this.notesDataList.setModels(meetingNoteListItems);
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.Loaded);
                        this.alert.hide();
                        return [3 /*break*/, 5];
                    case 4:
                        err_2 = _b.sent();
                        this.alert.show({
                            alertType: alert_1.AlertType.Error,
                            message: 'An error occurred trying to retrieve your meeting notes'
                        });
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.addAttachment = function () {
            var binaryFile = _this.fileUploadField.value;
            if (_this.fileUploadField.isValid() === false || !binaryFile) {
                return;
            }
            _this._newAttachments.unshift(binaryFile);
            _this.fileUploadField.clearValue();
            _this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.AttachmentAdded);
        };
        this.removeAttachment = function (index) { return function () {
            if (_this._newAttachments[index].id) {
                _this._newAttachments[index].isRemoved = true;
            }
            else {
                _this._newAttachments.splice(index, 1);
            }
            _this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.AttachmentRemoved);
        }; };
        this.buildRemoveAttachmentButton = function (attachment, index) {
            return new button_2.default({
                leftIcon: icons_1.Icons.Minus,
                onClick: _this.removeAttachment(index),
                isVisible: !attachment.isRemoved
            });
        };
        this.buildCancelRemoveAttachmentButton = function (attachment, index) {
            return new button_2.default({
                leftIcon: icons_1.Icons.Plus,
                onClick: _this.cancelRemoveAttachment(index),
                isVisible: attachment.isRemoved === true
            });
        };
        this.cancelRemoveAttachment = function (index) { return function () {
            _this._newAttachments[index].isRemoved = false;
            _this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.AttachmentRemoveCancelled);
        }; };
        this.buildDownloadAttachmentButton = function (attachment) {
            return new button_1.DownloadButton({
                api: _this.api,
                leftIcon: icons_1.Icons.Download,
                url: _this.api.getMeetingNoteAttachmentUrl(attachment.id),
                text: attachment.fileName
            });
        };
        this.buildEditNoteForm = function (note, index) { return function () {
            _this.editNoteForms[index] = new __1.Form([
                new __1.TextField('title', '', true, 100, 'required', note.title, fields_1.FieldType.TextBox, 'Note Title'),
                new fields_1.RichTextField('note', '', true, 'required', note.note)
            ], note);
            _this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.EditNoteClicked);
        }; };
        this.buildSaveNoteForm = function (note, index) { return __awaiter(_this, void 0, void 0, function () {
            var editNoteForm, updatedNote;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        editNoteForm = this.editNoteForms[index];
                        if (!editNoteForm.isValid()) {
                            return [2 /*return*/];
                        }
                        updatedNote = editNoteForm.model;
                        note.title = updatedNote.title;
                        note.note = updatedNote.note;
                        this.editNoteForms[index] = null;
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.Saving);
                        return [4 /*yield*/, this.api.post("".concat(api_1.EndpointUrls.Meetings, "/UpdateMeetingNote"), note)];
                    case 1:
                        _a.sent();
                        this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.Saved);
                        this._isLoading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var newMeetingNote, meetingNoteId, i, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.newTitleTextField.isValid() ||
                            !this.newNoteTextField.isValid()) {
                            this.saveButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        if (!this.noteContentRadioField.isValid() ||
                            this.noteContentRadioField.value !== 'Unrestricted') {
                            this._noteContentFieldInvalid = true;
                            this.saveButton.setProcessing(false);
                            this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.NoteContentInvalid);
                            return [2 /*return*/];
                        }
                        else {
                            this._noteContentFieldInvalid = false;
                        }
                        newMeetingNote = {
                            title: this.newTitleTextField.value,
                            note: this.newNoteTextField.value,
                            attachments: [],
                            meetingId: this._meetingId
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, this.api.post("".concat(api_1.EndpointUrls.Meetings, "/").concat(this._meetingId, "/Notes"), newMeetingNote)];
                    case 2:
                        meetingNoteId = _a.sent();
                        i = 0;
                        _a.label = 3;
                    case 3:
                        if (!(i < this._newAttachments.length)) return [3 /*break*/, 6];
                        this._newAttachments[i].meetingNoteId = meetingNoteId;
                        return [4 /*yield*/, this.api.uploadFile("".concat(api_1.EndpointUrls.Meetings, "/Notes/").concat(meetingNoteId, "/Attachment"), this._newAttachments[i])];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 3];
                    case 6:
                        this.saveButton.setProcessing(false);
                        this.alert.show({
                            alertType: alert_1.AlertType.Success,
                            heading: 'Saved',
                            message: 'Your notes have been saved'
                        });
                        this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.Saved);
                        this._newAttachments = [];
                        this.newNoteTextField.clearValue();
                        this.newTitleTextField.clearValue();
                        this.noteContentRadioField.clearValue();
                        this.loadMeeting(this._meetingId);
                        return [3 /*break*/, 8];
                    case 7:
                        err_3 = _a.sent();
                        this.saveButton.setProcessing(false);
                        this.alert.show({
                            alertType: alert_1.AlertType.Error,
                            message: 'An error occurred trying to save your meeting notes'
                        });
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        this._isVisible = false;
        this._isLoading = false;
        this._newAttachments = [];
        this.observationProvider = new __1.ObservationProvider();
        this.newNoteTextField = new fields_1.RichTextField('currentUserMeetingNote', '', true, 'required', null);
        this.newTitleTextField = new __1.TextField('currentUserMeetingNoteTitle', '', true, 100, 'required', null, fields_1.FieldType.TextBox, 'Note Title');
        this.noteContentRadioField = new fields_1.RadioButtonField('noteContentTypeField', '', true, [
            'Unrestricted',
            'Proprietary'
        ]);
        this.hideButton = new button_2.default({
            onClick: function () { return _this.hide(); },
            leftIcon: icons_1.Icons.ChevronLeft
        });
        this.saveButton = new button_2.default({
            onClick: function () { return _this.save(); },
            text: 'Save',
            processingText: 'Saving...'
        });
        this.fileUploadField = new fields_1.BinaryFileInputField('attachment', 'Attach File', false, fields_1.FileInputFileTypes.All, 50000);
        this.fileUploadField.observationProvider.observe(this.addAttachment, fields_1.FieldActions.ValueChanged);
        this.fileUploadField.setVisibility(false);
        this.alert = new __1.Alert();
        this.notesDataList = new dataList_1.DataList(function () { return []; }, false);
        this.deleteConfirmationPrompt = new __1.ConfirmationPrompt(true, 'Delete Note', 'Are you sure you want to delete this meeting note?', 'Delete', 'Cancel', 'Deleting...');
        this.deleteConfirmationPrompt.observationProvider.observe(this.deleteNote, __1.ConfirmationPromptActions.Confirmed);
        this.deleteConfirmationPrompt.observationProvider.observe(this.hideDeleteNoteConfirmation, __1.ConfirmationPromptActions.Cancelled);
    }
    Object.defineProperty(MeetingNotesForm.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotesForm.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotesForm.prototype, "meetingNotes", {
        get: function () {
            return this._meetingNotes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotesForm.prototype, "newAttachments", {
        get: function () {
            return this._newAttachments;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotesForm.prototype, "title", {
        get: function () {
            return 'Meeting Notes';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotesForm.prototype, "meetingNotesDisclaimer", {
        get: function () {
            return this._meetingNotesDisclaimer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotesForm.prototype, "noteContentFieldInvalid", {
        get: function () {
            return this._noteContentFieldInvalid;
        },
        enumerable: false,
        configurable: true
    });
    MeetingNotesForm.prototype.buildAttachmentFileNameField = function (attachment, index) {
        var fileNameTextField = new __1.TextField('fileName', '', false, 250, null, attachment.fileName);
        fileNameTextField.observationProvider.observe(function (fileName) {
            attachment.fileName = fileName;
        }, fields_1.FieldActions.ValueChanged);
        return fileNameTextField;
    };
    MeetingNotesForm.prototype.buildDeleteNoteButton = function (note) {
        var _this = this;
        return new button_2.default({
            leftIcon: icons_1.Icons.Trash,
            onClick: function () {
                _this.noteIdToDelete = note.id;
                _this.deleteConfirmationPrompt.show();
                _this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.DeleteNoteClicked);
            },
            isVisible: note.isDeletable
        });
    };
    MeetingNotesForm.prototype.buildEditNoteButton = function (note, index) {
        return new button_2.default({
            leftIcon: icons_1.Icons.Edit,
            onClick: this.buildEditNoteForm(note, index),
            isVisible: note.isEditable
        });
    };
    MeetingNotesForm.prototype.buildCancelEditNoteButton = function (note, index) {
        var _this = this;
        return new button_2.default({
            text: 'Cancel',
            onClick: function () {
                _this.editNoteForms[index] = null;
                _this.observationProvider.notifyObservers(__1.MeetingNotesFormActions.CancelEditNoteClicked);
            }
        });
    };
    MeetingNotesForm.prototype.buildSaveNoteButton = function (note, index) {
        var _this = this;
        return new button_2.default({
            text: 'Save',
            onClick: function () { return _this.buildSaveNoteForm(note, index); }
        });
    };
    return MeetingNotesForm;
}());
exports.default = MeetingNotesForm;
