"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeetingDetailFormPane = void 0;
var utils_1 = require("../../utils");
var api_1 = require("../../api");
var _1 = require(".");
var __1 = require("..");
var fields_1 = require("../fields");
var pages_1 = require("../../pages");
var departmentDropdown_1 = require("../fields/departmentDropdown");
var button_1 = require("../button");
var button_2 = require("../button/button");
var icons_1 = require("../icons");
var confirmationPrompt_1 = require("../confirmationPrompt");
var dataList_1 = require("../dataList");
var customers_1 = require("../customers");
var helpButton_1 = require("../button/helpButton");
var scheduledByField_1 = require("../fields/scheduledByField");
var meetingCalendarNotificationModal_1 = require("./meetingCalendarNotificationModal");
var MeetingDetailFormPane;
(function (MeetingDetailFormPane) {
    MeetingDetailFormPane["MeetingInfo"] = "MeetingInfo";
    MeetingDetailFormPane["Notes"] = "Notes";
    MeetingDetailFormPane["ActionItems"] = "ActionItems";
    MeetingDetailFormPane["History"] = "History";
    MeetingDetailFormPane["Notify"] = "Notify";
})(MeetingDetailFormPane || (exports.MeetingDetailFormPane = MeetingDetailFormPane = {}));
var MeetingDetailForm = /** @class */ (function () {
    function MeetingDetailForm(api, _eventId, navigator, currentUserManager) {
        var _this = this;
        this.api = api;
        this._eventId = _eventId;
        this.navigator = navigator;
        this.currentUserManager = currentUserManager;
        this.toggleEditMode = function () {
            _this.setEditMode(_this.isEditMode !== true);
        };
        this.setEditMode = function (isEditMode) {
            _this._isEditMode = isEditMode;
            if (_this.isEditMode) {
                _this.setDeactivateButtonVisibility();
                _this.closeButton.setVisibility(false);
                _this.cancelEditButton.setVisibility(true);
            }
            else {
                _this.deactivateButton.setVisibility(false);
                _this.closeButton.setVisibility(true);
                _this.cancelEditButton.setVisibility(false);
            }
            _this.fileDataList.setDeleteButtonVisibility(_this._isEditMode);
            _this.saveButton.setVisibility(_this.isEditMode);
            _this.saveAndCloseButton.setVisibility(_this.isEditMode);
            if (_this.meetingInvitesEnabledField) {
                _this.meetingInvitesEnabledField.setVisibility(_this._eventManager.isMeetingInvitesEnabled && _this.isEditMode);
            }
            _this.showMeetingInfo();
            _this.actionsDropdown.close();
            _this.actionsDropdown.dropdownButton.setDisabled(_this._isEditMode);
            _this.meetingNotesButton.setDisabled(_this._isEditMode);
            _this.actionItemsButton.setDisabled(_this._isEditMode);
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.EditModeToggled);
        };
        this.addFile = function () {
            var file = _this._addFileField.value;
            if (_this._addFileField.isValid() === false) {
                _this.alert.show({
                    alertType: __1.AlertType.Error,
                    heading: 'Invalid File',
                    message: _this._addFileField.errorMessage
                });
                return;
            }
            file.friendlyName = file.fileName;
            file.description = '';
            file.isDeletable = true;
            _this._fileDataList.addModel(file);
            _this._addFileField.clearValue();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.FileAdded);
        };
        this.showNotificationPane = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.meetingNotificationButtonClicked = true;
                this.submit();
                return [2 /*return*/];
            });
        }); };
        this.showHistoryTable = function () {
            _this._activePane = MeetingDetailFormPane.History;
            _this._historyTable.load();
            _this.meetingNotesForm.hide();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.ActivePaneChanged);
        };
        this.showMeetingInfo = function () {
            _this._activePane = MeetingDetailFormPane.MeetingInfo;
            _this.meetingNotesForm.hide();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.ActivePaneChanged);
        };
        this.showMeetingNotes = function () {
            _this._activePane = MeetingDetailFormPane.Notes;
            _this.meetingNotesForm.loadMeeting(_this._meetingId);
            _this.meetingNotesForm.show();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.ActivePaneChanged);
        };
        this.showActionItems = function () {
            _this._activePane = MeetingDetailFormPane.ActionItems;
            _this.meetingNotesForm.hide();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.ActivePaneChanged);
        };
        this.duplicateMeeting = function () {
            _this._originalMeetingTitle = "".concat(_this._form.model.name, " (#").concat(_this._form.model.meetingNumber, ")");
            var duplicateMeeting = __assign(__assign({}, _this._form.model), { isActive: true, venueId: null });
            delete duplicateMeeting.id;
            delete duplicateMeeting.name;
            delete duplicateMeeting.meetingNumber;
            _this._title = 'New Meeting';
            _this._form.setModel(duplicateMeeting);
            _this._meetingId = null;
            _this.enableForm();
            _this.setDeactivateButtonVisibility();
            _this.alert.hide();
            _this.duplicateMeetingButton.setVisibility(false);
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.RecordDuplicated);
            _this.setEditMode(true);
        };
        this.buildEditForm = function () { return __awaiter(_this, void 0, void 0, function () {
            var meetingInviteFieldLabel, attendeeTypeOptions, fields, _i, _a, field, _b, _c, field, _d, _e, field, _f, _g, field, _h, _j, field, _k;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        this._meetingStatusField = new fields_1.MeetingStatusField('status', 'Status', true);
                        this._attendeesNotifiedField = new fields_1.CheckBoxField('attendeesNotified', 'Attendees Notified');
                        this._attendeesNotifiedField.setVisibility(false);
                        meetingInviteFieldLabel = this._meetingId ? 'Send Meeting Invites' : 'Update Meeting Invites';
                        this._meetingInvitesEnabledField = new fields_1.CheckBoxField('meetingInvitesEnabled', meetingInviteFieldLabel);
                        this._meetingInvitesEnabledField.setVisibility(false);
                        this.preMeetingPickupCheckbox = new fields_1.CheckBoxField('preMeetingPickupAvailable', 'Pre-meeting golf cart available');
                        this.preMeetingPickupCheckbox.observationProvider.observe(this.setPreMeetingPickupFields, fields_1.FieldActions.ValueChanged);
                        this.preMeetingPickupLocation = new __1.TextField('preMeetingPickupLocation', 'Pickup Location', true, 100);
                        this.preMeetingPickupDropOffLocation = new __1.TextField('preMeetingDropOffLocation', 'Dropoff Location', true, 100);
                        this.preMeetingPickupCount = new fields_1.IntField('preMeetingPickupCount', '# of passengers', true);
                        this.preMeetingPickupTime = new fields_1.TimeField('preMeetingPickupTime', 'Pickup time', true);
                        this.preMeetingPickupComments = new __1.TextField('preMeetingPickupComments', 'Comments', false, 1000, null, null, fields_1.FieldType.TextArea);
                        this.postMeetingPickupCheckbox = new fields_1.CheckBoxField('postMeetingPickupAvailable', 'Post-meeting golf cart available');
                        this.postMeetingPickupCheckbox.observationProvider.observe(this.setPostMeetingDropoffFields, fields_1.FieldActions.ValueChanged);
                        this.postMeetingPickupLocation = new __1.TextField('postMeetingPickupLocation', 'Pickup Location', true, 100);
                        this.postMeetingPickupDropOffLocation = new __1.TextField('postMeetingDropOffLocation', 'Dropoff Location', true, 100);
                        this.postMeetingPickupCount = new fields_1.IntField('postMeetingPickupCount', '# of passengers', true);
                        this.postMeetingPickupTime = new fields_1.TimeField('postMeetingPickupTime', 'Pickup time', true);
                        this.postMeetingPickupComments = new __1.TextField('postMeetingPickupComments', 'Comments', false, 1000, null, null, fields_1.FieldType.TextArea);
                        attendeeTypeOptions = [
                            { label: 'Individual Attendees', value: AttendeeSelectionTypes.IndividualAttendees },
                            { label: 'All Company Attendees', value: AttendeeSelectionTypes.AllCompanyAttendees },
                            { label: 'Business Area', value: AttendeeSelectionTypes.BusinessArea },
                            { label: 'Operating Company', value: AttendeeSelectionTypes.Department },
                        ];
                        this._attendeeTypeDropdown = new fields_1.NumberDropdownField('attendeeType', 'Company Attendees', true, attendeeTypeOptions, 'Attendee type is required', 1);
                        this._attendeeTypeDropdown.observationProvider.observe(this.attendeeTypeDropdownChanged, fields_1.FieldActions.ValueChanged);
                        this._businessAreaAttendeesDropdown = new fields_1.BusinessAreaDropdown(this.api, 'attendeeBusinessAreaId', 'Business area', true, 'Business area is required');
                        this._departmentAttendeesDropdown = new departmentDropdown_1.default(this.api, 'attendeeDepartmentId', true, null, 'Operating company');
                        this._overrideAttendeeCountCheckbox = new fields_1.CheckBoxField('isOverrideTotal', 'Override attendee count');
                        this._overrideAttendeeCountCheckbox.observationProvider.observe(this.attendeeCountOverrideChanged, fields_1.FieldActions.ValueChanged);
                        this._totalAttendeeCountField = new fields_1.IntField('totalAttendees', 'Total attendee count', true, 'Attendee count is required');
                        this._totalAttendeeCountField.isDisabled = true;
                        this._foodNeededField = new fields_1.CheckBoxField('foodNeeded', 'Food Needed');
                        this._venueField = new fields_1.VenueField(this.api, this._eventId, 'venueId', 'Venue', false, null, null, 'Unassigned');
                        this._meetingCategoryField = new fields_1.MeetingCategoryField(this.api, 'defaultMeetingCategoryId', 'Meeting Category', true);
                        this._meetingCategoryField.observationProvider.observe(this.setScheduledByVisibility, fields_1.FieldActions.ValueChanged);
                        this._scheduledByField = new scheduledByField_1.default(this.api, 'scheduledById', 'Scheduled By', true, 'required');
                        this._scheduledByField.setVisibility(false);
                        this._urlField = new __1.TextField('url', 'Meeting Url', false, 2000);
                        this._startTimeField = new fields_1.TimeField('startTime', 'Start Time', true, 'Required');
                        this._endTimeField = new fields_1.TimeField('endTime', 'End Time', true);
                        this._meetingNameField = new __1.TextField('name', 'Meeting Name', true, 250);
                        this._meetingDateField = new fields_1.MeetingDateDropdownField(this.api, this._eventId, 'date', 'Date', true);
                        this._subjectField = new __1.TextField('subject', 'Subject', false, 250, null, null, fields_1.FieldType.TextArea);
                        this._generalInfoFields = [
                            this._meetingNameField, //0 sm-6
                            this._meetingDateField, //1 sm-2
                            this._startTimeField, //2 sm-2
                            this._endTimeField, //3 sm-2
                            this._subjectField, //4 sm-6
                            this._attendeesNotifiedField, //5
                            this._meetingCategoryField, //6
                            this._scheduledByField, //7
                            this._foodNeededField, //8
                            new fields_1.VenuePreferenceField(this.api, this._eventId, 'venuePreferenceId', 'Venue Preference', true), //9
                            this._venueField, //10
                            this._urlField, //11,
                        ];
                        this._printedCommentsField = new __1.TextField('printedComments', 'Comments', false, 1000, null, null, fields_1.FieldType.TextArea);
                        this._commentFields = [
                            this._printedCommentsField
                        ];
                        this._attendeeCountFields = [
                            this._overrideAttendeeCountCheckbox,
                            this._totalAttendeeCountField
                        ];
                        this._preMeetingPickupFields = [
                            this.preMeetingPickupCheckbox,
                            this.preMeetingPickupLocation,
                            this.preMeetingPickupDropOffLocation,
                            this.preMeetingPickupCount,
                            this.preMeetingPickupTime,
                            this.preMeetingPickupComments
                        ];
                        this._postMeetingPickupFields = [
                            this.postMeetingPickupCheckbox,
                            this.postMeetingPickupLocation,
                            this.postMeetingPickupDropOffLocation,
                            this.postMeetingPickupCount,
                            this.postMeetingPickupTime,
                            this.postMeetingPickupComments
                        ];
                        fields = [
                            this._businessAreaAttendeesDropdown,
                            this._departmentAttendeesDropdown,
                            this._meetingStatusField,
                            this._meetingInvitesEnabledField
                        ];
                        for (_i = 0, _a = this._generalInfoFields; _i < _a.length; _i++) {
                            field = _a[_i];
                            fields.push(field);
                        }
                        for (_b = 0, _c = this._commentFields; _b < _c.length; _b++) {
                            field = _c[_b];
                            fields.push(field);
                        }
                        for (_d = 0, _e = this._attendeeCountFields; _d < _e.length; _d++) {
                            field = _e[_d];
                            fields.push(field);
                        }
                        for (_f = 0, _g = this.preMeetingPickupFields; _f < _g.length; _f++) {
                            field = _g[_f];
                            fields.push(field);
                        }
                        for (_h = 0, _j = this.postMeetingPickupFields; _h < _j.length; _h++) {
                            field = _j[_h];
                            fields.push(field);
                        }
                        this._form = new __1.Form(fields);
                        this._attendeeForms = [];
                        this._customerSearchField = new fields_1.CustomerSearchField(this.api, '', false, null, true);
                        this._customerSearchField.quickAddCustomerForm.observationProvider.observe(this.quickAddCustomersCreated, customers_1.QuickAddCustomerFormActions.CustomerCreated);
                        this.meetingGuestDataList.setModels([]);
                        this.additionalNotificationRecipientsDataList.setModels([]);
                        this.setAttendeeType();
                        _k = this;
                        return [4 /*yield*/, this.api.getEventManager(this._eventId)];
                    case 1:
                        _k._eventManager = _l.sent();
                        this._meetingStatusField.isConfirmDisabled = !this._eventManager.isMeetingFinalizedStatusEnabled;
                        this._meetingStatusField.isWorkingDisabled = !this._eventManager.isMeetingWorkingStatusEnabled;
                        this._meetingStatusField.isRequestDisabled = !this._eventManager.canSubmitMeetingRequests;
                        if (!this._eventManager.meetingConfigurations.golfCartsEnabled) {
                            this.preMeetingPickupCheckbox.setVisibility(false);
                        }
                        if (!this._eventManager.meetingConfigurations.golfCartsEnabled) {
                            this.postMeetingPickupCheckbox.setVisibility(false);
                        }
                        this._golfCartsEnabled = this._eventManager.meetingConfigurations.golfCartsEnabled;
                        this._customerSearchField.setQuickAddEnabled(this._eventManager.canQuickAddCustomers);
                        this._foodNeededField.setVisibility(this._eventManager.meetingConfigurations.meetingsDiningEnabled);
                        this._meetingInvitesEnabledField.setVisibility(this._eventManager.isMeetingInvitesEnabled && this.isEditMode);
                        this.setPreMeetingPickupFields();
                        this.setPostMeetingDropoffFields();
                        this._eventAttendeeDropdown = new fields_1.EventAttendeeSearchField(this.api, this._eventId, 'Attendee', false, null, true, 'eventAttendeeIds', 'email', { searchPhrase: '', inMeetings: true });
                        return [2 /*return*/];
                }
            });
        }); };
        this.setScheduledByVisibility = function () {
            if (_this._meetingCategoryField.selectedOption && _this._meetingCategoryField.selectedOption.requireScheduledBy) {
                _this._scheduledByField.setVisibility(true);
            }
            else {
                _this._scheduledByField.setVisibility(false);
            }
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.MeetingCategoryChanged);
        };
        this.setDeactivateButtonVisibility = function () {
            _this.deactivateButton.setVisibility(_this._eventManager.getCanDeactivateMeetings(_this._form.model));
        };
        this.attendeeCountOverrideChanged = function () {
            if (_this._overrideAttendeeCountCheckbox.value) {
                _this._totalAttendeeCountField.isDisabled = false;
            }
            else {
                _this.setAttendeeCount();
                _this._totalAttendeeCountField.isDisabled = true;
            }
        };
        this.setAttendeeCount = function () {
            if (!_this._overrideAttendeeCountCheckbox.value) {
                var guestCount = _this.meetingGuestDataList.items.filter(function (guestItem) { return !guestItem.form.model.isRemoved; }).length;
                var attendeeCount = _this._attendeeForms.filter(function (attendeeForm) { return !attendeeForm.model.isRemoved; }).length;
                _this._totalAttendeeCountField.setValue(guestCount + attendeeCount);
            }
        };
        this.attendeeTypeDropdownChanged = function () {
            _this.setAttendeeType();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.AttendeeTypeChanged);
        };
        this.setAttendeeType = function () {
            var bussinessAreaVisible = false;
            var departmentVisible = false;
            var attendeesVisible = false;
            switch (_this._attendeeTypeDropdown.value) {
                case AttendeeSelectionTypes.IndividualAttendees:
                    attendeesVisible = true;
                    break;
                case AttendeeSelectionTypes.AllCompanyAttendees:
                    break;
                case AttendeeSelectionTypes.BusinessArea:
                    attendeesVisible = true;
                    bussinessAreaVisible = true;
                    break;
                case AttendeeSelectionTypes.Department:
                    attendeesVisible = true;
                    departmentVisible = true;
                    break;
            }
            _this._businessAreaAttendeesDropdown.setVisibility(bussinessAreaVisible);
            _this._departmentAttendeesDropdown.setVisibility(departmentVisible);
            _this._individualAttendeeSelectionVisible = attendeesVisible;
        };
        this.setPreMeetingPickupFields = function () {
            var pickupAvailable = _this._eventManager.meetingConfigurations.golfCartsEnabled && _this.preMeetingPickupCheckbox.value == true;
            _this.preMeetingPickupLocation.setVisibility(pickupAvailable);
            _this.preMeetingPickupDropOffLocation.setVisibility(pickupAvailable);
            _this.preMeetingPickupCount.setVisibility(pickupAvailable);
            _this.preMeetingPickupTime.setVisibility(pickupAvailable);
            _this.preMeetingPickupComments.setVisibility(pickupAvailable);
            if (pickupAvailable && !_this.preMeetingPickupTime.value) {
                _this.preMeetingPickupTime.setValue(_this._form.model.startTime);
            }
        };
        this.setPostMeetingDropoffFields = function () {
            var pickupAvailable = _this._eventManager.meetingConfigurations.golfCartsEnabled && _this.postMeetingPickupCheckbox.value == true;
            _this.postMeetingPickupLocation.setVisibility(pickupAvailable);
            _this.postMeetingPickupDropOffLocation.setVisibility(pickupAvailable);
            _this.postMeetingPickupCount.setVisibility(pickupAvailable);
            _this.postMeetingPickupTime.setVisibility(pickupAvailable);
            _this.postMeetingPickupComments.setVisibility(pickupAvailable);
            if (pickupAvailable && !_this.postMeetingPickupTime.value) {
                _this.postMeetingPickupTime.setValue(_this._form.model.endTime);
            }
        };
        this.addAttendeeForm = function (attendee) {
            var leadCheckbox = new fields_1.CheckBoxField('isLead', 'POC');
            var meetingAttendeeForm = new __1.Form([
                leadCheckbox,
                new fields_1.CheckBoxField('isMeetAndGreet', 'Meet & Greet')
            ]);
            leadCheckbox.observationProvider.observe(function () { return _this.leadAttendeeChanged(leadCheckbox); }, fields_1.FieldActions.ValueChanged);
            meetingAttendeeForm.setModel(attendee);
            _this._attendeeForms.push(meetingAttendeeForm);
            _this._eventAttendeeDropdown.clearValue();
        };
        this.addGuest = function () {
            var selectedCustomers = _this._customerSearchField.selectedResults;
            if (!selectedCustomers || !selectedCustomers.length) {
                return;
            }
            var _loop_1 = function (customer) {
                if (_this.meetingGuestDataList.findIndex(function (guestItem) { return guestItem.customerId === customer.customerId; }) >= 0) {
                    return "continue";
                }
                _this.meetingGuestDataList.addModel({
                    customerId: customer.customerId,
                    customerName: customer.fullName,
                    customerTitle: customer.title,
                    customerCompany: customer.company,
                    customerEmail: customer.email,
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    phone: customer.phone,
                    rank: customer.rank,
                    customerCompanyId: customer.companyId
                }, true);
            };
            for (var _i = 0, selectedCustomers_1 = selectedCustomers; _i < selectedCustomers_1.length; _i++) {
                var customer = selectedCustomers_1[_i];
                _loop_1(customer);
            }
            _this._customerSearchField.clearSelected();
        };
        this.quickAddCustomersCreated = function () {
            var customer = _this._customerSearchField.quickAddCustomerForm.customer;
            if (_this.meetingGuestDataList.findIndex(function (guestItem) { return guestItem.customerId === customer.id; }) < 0) {
                var customerCompanyName = customer.customerCompanyName || customer.newCustomerCompanyName;
                _this.meetingGuestDataList.addModel({
                    customerId: customer.customerId,
                    customerName: customer.firstName + ' ' + customer.lastName,
                    customerEmail: customer.email,
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    phone: customer.phone,
                    customerCompanyId: customer.customerCompanyId,
                    customerTitle: customer.title,
                    customerCompany: customerCompanyName,
                    rank: customer.rank
                }, true);
            }
            _this._customerSearchField.clearSelected();
        };
        this.buildIsGuestLeadCheckbox = function () {
            var field = new fields_1.CheckBoxField('isLead', 'Is Lead');
            field.observationProvider.observe(function () { return _this.meetingGuestLeadChanged(field); }, fields_1.FieldActions.ValueChanged);
            return field;
        };
        this.addAdditionalNotificationRecipients = function () {
            var selectedAttendees = _this._additionalNotificationRecipientAttendeeField.selectedAttendees;
            var _loop_2 = function (attendee) {
                if (_this.additionalNotificationRecipientsDataList.findIndex(function (recipient) { return attendee.eventAttendeeId === recipient.eventAttendeeId; }) >= 0) {
                    return "continue";
                }
                _this.additionalNotificationRecipientsDataList.addModel({
                    eventAttendeeId: attendee.eventAttendeeId,
                    fullName: attendee.fullName
                }, true);
            };
            for (var _i = 0, selectedAttendees_1 = selectedAttendees; _i < selectedAttendees_1.length; _i++) {
                var attendee = selectedAttendees_1[_i];
                _loop_2(attendee);
            }
            _this._additionalNotificationRecipientAttendeeField.clearValue();
        };
        this.meetingGuestLeadChanged = function (field) {
            if (_this._isGuestLeadChanging || _this.isLoading) {
                return;
            }
            _this._isGuestLeadChanging = true;
            for (var _i = 0, _a = _this.meetingGuestDataList.items; _i < _a.length; _i++) {
                var guest = _a[_i];
                if (guest.form.getFieldByKey('isLead') !== field) {
                    guest.form.setModel(__assign(__assign({}, guest.form.model), { isLead: false }));
                }
                else {
                    guest.form.setModel(__assign(__assign({}, guest.form.model), { isLead: field.value }));
                }
            }
            _this._isGuestLeadChanging = false;
        };
        this.removeAttendee = function (index) {
            if (!_this._attendeeForms[index].model.id) {
                _this._attendeeForms.splice(index, 1);
            }
            else {
                _this._attendeeForms[index].model.isRemoved = true;
            }
            _this.setAttendeeCount();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.AttendeeRemoved);
        };
        this.addAttendees = function () {
            var selectedAttendees = _this._eventAttendeeDropdown.selectedResults;
            if (!selectedAttendees || !selectedAttendees.length) {
                return;
            }
            var _loop_3 = function (i) {
                if (_this.attendeeForms.some(function (form) { return form.model.eventAttendeeId == selectedAttendees[i].eventAttendeeId; })) {
                    return "continue";
                }
                _this.addAttendeeForm({
                    eventAttendeeId: selectedAttendees[i].eventAttendeeId,
                    fullName: selectedAttendees[i].fullName,
                    title: selectedAttendees[i].title,
                    department: selectedAttendees[i].department
                });
            };
            for (var i = 0; i < selectedAttendees.length; i++) {
                _loop_3(i);
            }
            _this._eventAttendeeDropdown.clearValue();
            _this.setAttendeeCount();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.AttendeeAdded);
        };
        this.cancelRemoveAttendee = function (index) {
            _this._attendeeForms[index].model.isRemoved = false;
            _this.setAttendeeCount();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.AttendeeRemovalCancelled);
        };
        this.checkForAttendeeConflicts = function () { return __awaiter(_this, void 0, void 0, function () {
            var request, _a, err_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(!this._attendeeForms || !this._attendeeForms.length)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.submit()];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                    case 2:
                        _b.trys.push([2, 7, , 8]);
                        this._isLoading = true;
                        this._form.setModelValues();
                        request = {
                            eventId: this._eventId,
                            eventAttendeeIds: this.attendeeForms.filter(function (attendee) { return !attendee.model.isRemoved; }).map(function (attendee) { return attendee.model.eventAttendeeId; }),
                            meetingId: this._meetingId,
                            meetingDate: this._form.model.date,
                            startTime: this._form.model.startTime,
                            endTime: this._form.model.endTime
                        };
                        _a = this;
                        return [4 /*yield*/, this.api.post(api_1.EndpointUrls.Meetings + '/CheckForAttendeeScheduleConflicts', request)];
                    case 3:
                        _a._attendeeConflicts = _b.sent();
                        this._isLoading = false;
                        if (!this._attendeeConflicts.length) return [3 /*break*/, 4];
                        this._selectedConflictCheckboxes = this.attendeeConflicts.map(function (conflict) {
                            var checkbox = new fields_1.CheckBoxField(conflict.eventAttendeeId.toString(), conflict.usersName);
                            checkbox.observationProvider.observe(_this.conflictCheckboxesChanged, fields_1.FieldActions.ValueChanged);
                            return checkbox;
                        });
                        this.conflictCheckboxesChanged();
                        this._attendeeConflictModalOpen = true;
                        this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.PromptForConflicts);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.submit()];
                    case 5:
                        _b.sent();
                        return [2 /*return*/];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _b.sent();
                        this._isLoading = false;
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); };
        this.conflictCheckboxesChanged = function () {
            var anyConflictsRemoved = _this._selectedConflictCheckboxes.some(function (checkbox) { return checkbox.value === true; });
            _this.removeConflictsButton.setDisabled(!anyConflictsRemoved);
            _this.saveWithConflictsButton.setDisabled(anyConflictsRemoved);
        };
        this.removeSelectedConflicts = function () {
            var attendeeIndeciesToRemove = _this._selectedConflictCheckboxes
                .map(function (conflictCheckbox, index) {
                if (conflictCheckbox.value === true) {
                    return index;
                }
                else {
                    return null;
                }
            });
            for (var i = 0; i < attendeeIndeciesToRemove.length; i++) {
                if (attendeeIndeciesToRemove[i] !== null) {
                    _this.removeAttendee(attendeeIndeciesToRemove[i]);
                }
            }
            _this.closeConflictModal();
            _this.submit();
        };
        this.closeConflictModal = function () {
            _this._attendeeConflictModalOpen = false;
            _this.saveButton.setProcessing(false);
            _this.saveAndCloseButton.setProcessing(false);
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.ConflictModalClosed);
        };
        this.deactivateMeeting = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.api.delete(api_1.EndpointUrls.Meetings, this._meetingId)];
                    case 1:
                        _a.sent();
                        this._form.model.isActive = false;
                        this._isOpen = false;
                        this.confirmation.hide();
                        this.confirmation.deleteButton.setProcessing(false);
                        this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.RecordDeactivated, this._form.model);
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.confirmation.deleteButton.setProcessing(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.submit = function () { return __awaiter(_this, void 0, void 0, function () {
            var meetingInviteDataChanged, response, newMeeting, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.alert.hide();
                        if (!this.isFormValid()) {
                            this.saveButton.setProcessing(false);
                            this.saveAndCloseButton.setProcessing(false);
                            return [2 /*return*/, false];
                        }
                        this._form.model.guests = this.meetingGuestDataList.getModels();
                        this._form.model.additionalNotificationRecipients = this.additionalNotificationRecipientsDataList.getModels();
                        this.setModelAttendeeSelection();
                        meetingInviteDataChanged = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 12, , 13]);
                        if (!this._meetingId) return [3 /*break*/, 4];
                        this.setFormMeetingFileModelsToSubmit();
                        return [4 /*yield*/, this.api.post("".concat(api_1.EndpointUrls.Meetings, "/").concat(this._meetingId), this._form.model)];
                    case 2:
                        response = _a.sent();
                        meetingInviteDataChanged = response.meetingInviteDataChanged;
                        return [4 /*yield*/, this.uploadNewFiles()];
                    case 3:
                        _a.sent();
                        this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.RecordUpdated, this._form.model);
                        return [3 /*break*/, 7];
                    case 4:
                        this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.RecordAdding, this._form.model);
                        this.setFormMeetingFileModelsToSubmit();
                        return [4 /*yield*/, this.api.post(api_1.EndpointUrls.Meetings, this._form.model)];
                    case 5:
                        newMeeting = _a.sent();
                        this._form.model.id = newMeeting.id;
                        this._meetingId = newMeeting.id;
                        this._form.model.meetingNumber = newMeeting.meetingNumber;
                        return [4 /*yield*/, this.uploadNewFiles()];
                    case 6:
                        _a.sent();
                        this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.RecordAdded, this._form.model);
                        meetingInviteDataChanged = true;
                        _a.label = 7;
                    case 7:
                        if (!this.meetingNotificationButtonClicked) return [3 /*break*/, 8];
                        this.meetingNotificationButtonClicked = false;
                        this.notificationModal.show(this._meetingId);
                        return [3 /*break*/, 11];
                    case 8:
                        if (!(this.meetingInvitesEnabledField.value && meetingInviteDataChanged)) return [3 /*break*/, 9];
                        this.calendarNotificationModal.show(this._meetingId);
                        return [3 /*break*/, 11];
                    case 9: return [4 /*yield*/, this.saveComplete()];
                    case 10:
                        _a.sent();
                        _a.label = 11;
                    case 11: return [2 /*return*/, true];
                    case 12:
                        err_3 = _a.sent();
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'Unable to Save',
                            message: 'An error occurred while trying to save this meeting.'
                        });
                        this.saveButton.setProcessing(false);
                        this.saveAndCloseButton.setProcessing(false);
                        return [2 /*return*/, false];
                    case 13: return [2 /*return*/];
                }
            });
        }); };
        this.saveComplete = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.closeOnSave) return [3 /*break*/, 1];
                        this.close();
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.buildEditForm()];
                    case 2:
                        _a.sent();
                        this._title = 'Meeting Detail';
                        this.duplicateMeetingButton.setVisibility(this._eventManager.canDuplicateMeetings);
                        this.actionsDropdown.setVisibility(true);
                        this._historyTable = new pages_1.HistoryTable(pages_1.HistoryTypes.MeetingHistory, this._meetingId, this.api);
                        this._historyTable.searchBoxPlaceholder = 'By note';
                        this.loadMeeting(this._meetingId);
                        this.setEditMode(false);
                        this.saveButton.setProcessing(false);
                        this.saveAndCloseButton.setProcessing(false);
                        this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.Submitted, this._form.model);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.setFormMeetingFileModelsToSubmit = function () {
            _this._form.model.files = [];
            for (var i = 0; i < _this._fileDataList.items.length; i++) {
                var file = _this._fileDataList.items[i].form.model;
                if (file.id) {
                    var meetingFile = __assign(__assign({}, file), { buffer: '', fileUrl: '' });
                    delete meetingFile.formFile;
                    _this._form.model.files.push(meetingFile);
                }
            }
        };
        this.uploadNewFiles = function () { return __awaiter(_this, void 0, void 0, function () {
            var i, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        i = 0;
                        _b.label = 1;
                    case 1:
                        if (!(i < this._fileDataList.items.length)) return [3 /*break*/, 4];
                        this._fileDataList.items[i].form.model.meetingId = this._form.model.id;
                        if (!!this._fileDataList.items[i].form.model.id) return [3 /*break*/, 3];
                        _a = this._fileDataList.items[i].form.model;
                        return [4 /*yield*/, this.api.uploadFile("".concat(api_1.EndpointUrls.Meetings, "/Files"), this._fileDataList.items[i].form.model)];
                    case 2:
                        _a.id = _b.sent();
                        _b.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.isFormValid = function () {
            _this._form.submit();
            var isValid = _this._form.isValid();
            for (var _i = 0, _a = _this._attendeeForms; _i < _a.length; _i++) {
                var form = _a[_i];
                form.submit();
                isValid = isValid && form.isValid();
            }
            if (_this._startTimeField.value > _this._endTimeField.value) {
                isValid = false;
                _this.alert.show({
                    alertType: __1.AlertType.Error,
                    message: 'The meeting start time must be before the end time.',
                    heading: 'Invalid Meeting Time'
                });
            }
            isValid = isValid && _this.meetingGuestDataList.areAllValid();
            isValid = isValid && _this._fileDataList.areAllValid();
            return isValid;
        };
        this.open = function (meetingId) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.alert.hide();
                        this._activePane = MeetingDetailFormPane.MeetingInfo;
                        this.setEditMode(false);
                        this._originalMeetingTitle = '';
                        return [4 /*yield*/, this.buildEditForm()];
                    case 1:
                        _a.sent();
                        this.enableForm();
                        this._meetingId = meetingId;
                        this._isOpen = true;
                        this.saveButton.setProcessing(false);
                        this.saveAndCloseButton.setProcessing(false);
                        if (meetingId) {
                            this._title = 'Meeting Detail';
                            this.duplicateMeetingButton.setVisibility(this._eventManager.canDuplicateMeetings);
                            this.actionsDropdown.setVisibility(true);
                            this._historyTable = new pages_1.HistoryTable(pages_1.HistoryTypes.MeetingHistory, meetingId, this.api);
                            this._historyTable.searchBoxPlaceholder = 'By note';
                            this.editButton.setVisibility(true);
                        }
                        else {
                            this._title = 'New Meeting';
                            this.setEditMode(true);
                            this.actionsDropdown.setVisibility(false);
                            this.editButton.setVisibility(false);
                        }
                        this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.Opened);
                        if (!meetingId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.loadMeeting(meetingId)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        this.guestsArrivedButton.setDisabled(true);
                        this.setFormToDefaultMeeting();
                        this.downloadButtons = [];
                        if (!(this._eventManager.defaultMeetingStatus == pages_1.MeetingStatuses.Requested)) return [3 /*break*/, 5];
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.Loading);
                        return [4 /*yield*/, this.api.get(api_1.EndpointUrls.Events + '/' + this._eventId + '/BuildMeetingAttendee/' + this.currentUserManager.userId)
                                .then(function (attendee) {
                                if (attendee) {
                                    attendee.isLead = true;
                                    _this.addAttendeeForm(attendee);
                                    _this._isLoading = false;
                                    _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.Loaded);
                                }
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        this.loadMeeting = function (meetingId) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isLoading = true;
                        return [4 /*yield*/, this.api.get(api_1.EndpointUrls.Meetings + '/' + meetingId)
                                .then(function (meetingDetail) {
                                _this.form.setModel(meetingDetail);
                                _this.downloadButtons = [];
                                for (var _i = 0, _a = _this.form.model.attendees; _i < _a.length; _i++) {
                                    var attendee = _a[_i];
                                    _this.addAttendeeForm(attendee);
                                }
                                _this.meetingGuestDataList.setModels(_this.form.model.guests);
                                _this.additionalNotificationRecipientsDataList.setModels(_this.form.model.additionalNotificationRecipients);
                                if (meetingDetail.attendeesNotified) {
                                    _this._attendeesNotifiedField.setVisibility(true);
                                }
                                else {
                                    _this._attendeesNotifiedField.setVisibility(false);
                                }
                                if (meetingDetail.meetingInvitesSent) {
                                    _this._meetingInvitesEnabledField.isDisabled = true;
                                }
                                if (_this.form.model.attendeeBusinessAreaId) {
                                    _this._attendeeTypeDropdown.setValue(AttendeeSelectionTypes.BusinessArea);
                                }
                                else if (_this.form.model.attendeeDepartmentId) {
                                    _this._attendeeTypeDropdown.setValue(AttendeeSelectionTypes.Department);
                                }
                                else if (_this.form.model.allAttendees) {
                                    _this._attendeeTypeDropdown.setValue(AttendeeSelectionTypes.AllCompanyAttendees);
                                }
                                else {
                                    _this._attendeeTypeDropdown.setValue(AttendeeSelectionTypes.IndividualAttendees);
                                }
                                _this.notifyButton.setVisibility(_this._eventManager.canSendMeetingNotifications);
                                _this.setAttendeeType();
                                _this._fileDataList.setModels(meetingDetail.files);
                                if (!_this.form.model.isActive) {
                                    _this.alert.show({
                                        alertType: __1.AlertType.Error,
                                        heading: 'Inactive Meeting',
                                        message: 'This meeting has been deactivated and cannot be edited. Deactivated meetings do not show up on reports.',
                                        button: new button_2.default({
                                            text: 'Reactivate',
                                            onClick: _this.reactivateMeeting,
                                            isVisible: _this._eventManager.canCreateMeetings,
                                            processingText: 'Activating...'
                                        })
                                    });
                                    _this.disableForm();
                                }
                                if (_this.form.model.status !== pages_1.MeetingStatuses.Requested &&
                                    !_this._eventManager.canEditMeetings) {
                                    _this.disableForm();
                                }
                                if (_this.form.model.notesCount > 0) {
                                    _this.meetingNotesButton.setBadge(_this.form.model.notesCount.toString());
                                }
                                else {
                                    _this.meetingNotesButton.setBadge('');
                                }
                                _this.meetingNotesButton.setVisibility(_this._eventManager.isMeetingNotesEnabled &&
                                    (_this._eventManager.canViewEditMeetingNotes || _this.form.model.attendees.findIndex(function (attendee) { return attendee.userId == _this.currentUserManager.userId; }) >= 0));
                                _this.guestsArrivedButton.setDisabled(!_this._eventManager.canChangeMeetingGuestArrived);
                                if (_this.form.model.guestsArrived) {
                                    _this.guestsArrivedButton.setText('Yes');
                                    _this.guestsArrivedButton.setLeftIcon(icons_1.Icons.Checked);
                                }
                                else {
                                    _this.guestsArrivedButton.setText('No');
                                    _this.guestsArrivedButton.setLeftIcon(icons_1.Icons.Unchecked);
                                }
                                _this._isLoading = false;
                                _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.Loaded);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.guestsArrivedButtonClicked = function () {
            var newGuestsArrivedStatus = _this.form.model.guestsArrived !== true;
            _this.api.put(api_1.EndpointUrls.Meetings + '/GuestsArrived', _this._meetingId, { arrived: newGuestsArrivedStatus })
                .then(function () {
                _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.RecordUpdated, _this._form.model);
            });
            _this.form.model.guestsArrived = newGuestsArrivedStatus;
            if (_this.form.model.guestsArrived) {
                _this.guestsArrivedButton.setText('Yes');
                _this.guestsArrivedButton.setLeftIcon(icons_1.Icons.Checked);
            }
            else {
                _this.guestsArrivedButton.setText('No');
                _this.guestsArrivedButton.setLeftIcon(icons_1.Icons.Unchecked);
            }
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.GuestArrivedChanged);
        };
        this.reactivateMeeting = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.post(api_1.EndpointUrls.Meetings + '/' + this._meetingId + '/activate', {})];
                    case 1:
                        _a.sent();
                        this.alert.show({
                            alertType: __1.AlertType.Success,
                            heading: 'Meeting Activated',
                            message: 'This meeting has been reactivated successfully'
                        });
                        this._form.model.isActive = true;
                        this.setDeactivateButtonVisibility();
                        this.enableForm();
                        this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.RecordReactivated, this._form.model);
                        return [2 /*return*/];
                }
            });
        }); };
        this.setFormEditability = function (isEnabled) {
            _this._isDisabled = !isEnabled;
            _this.editButton.setVisibility(!_this._isDisabled);
            _this.saveButton.setDisabled(_this._isDisabled);
            _this.saveAndCloseButton.setDisabled(_this._isDisabled);
            _this._form.isDisabled = _this._isDisabled;
            _this.addAttendeeButton.setDisabled(_this._isDisabled);
            for (var _i = 0, _a = _this.attendeeForms; _i < _a.length; _i++) {
                var attendeeForm = _a[_i];
                attendeeForm.isDisabled = _this._isDisabled;
            }
            _this._customerSearchField.isDisabled = _this._isDisabled;
            _this.addGuestButton.setDisabled(_this._isDisabled);
            _this.meetingGuestDataList.setIsEditable(!_this._isDisabled);
            _this.additionalNotificationRecipientsDataList.setIsEditable(!_this._isDisabled);
            _this.additionalNotificationRecipientAttendeeField.isDisabled = _this._isDisabled;
            _this._eventAttendeeDropdown.isDisabled = _this._isDisabled;
            _this.addAdditionalNotificationRecipientButton.setDisabled(_this._isDisabled);
            _this._businessAreaAttendeesDropdown.isDisabled = _this._isDisabled;
            _this._departmentAttendeesDropdown.isDisabled = _this._isDisabled;
            _this._attendeeTypeDropdown.isDisabled = _this._isDisabled;
            _this._fileDataList.setIsEditable(!_this._isDisabled);
            _this._addFileField.isDisabled = _this._isDisabled;
            _this._venueField.isDisabled = !_this._eventManager.canAssignVenues;
        };
        this.disableForm = function () {
            _this.setFormEditability(false);
        };
        this.enableForm = function () {
            _this.setFormEditability(true);
        };
        this.close = function () {
            if (_this.meetingNotesForm.isVisible) {
                _this.showMeetingInfo();
                return;
            }
            _this._isOpen = false;
            _this.setFormToDefaultMeeting();
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.Closed);
        };
        this.buildRemoveAttendeeButton = function (attendee, index) {
            if (_this._isDisabled) {
                return;
            }
            return new button_2.default({
                leftIcon: icons_1.Icons.Minus,
                onClick: function () { return _this.removeAttendee(index); },
                isVisible: !attendee.isRemoved
            });
        };
        this.buildCancelRemoveAttendeeButton = function (attendee, index) {
            if (_this._isDisabled) {
                return;
            }
            return new button_2.default({
                leftIcon: icons_1.Icons.Plus,
                onClick: function () { return _this.cancelRemoveAttendee(index); },
                isVisible: attendee.isRemoved
            });
        };
        this.leadAttendeeChanged = function (field) {
            if (_this._isLeadAttendeeChanging || _this.isLoading) {
                return;
            }
            _this._isLeadAttendeeChanging = true;
            for (var _i = 0, _a = _this.attendeeForms; _i < _a.length; _i++) {
                var attendeeForm = _a[_i];
                if (attendeeForm.getFieldByKey('isLead') !== field) {
                    attendeeForm.setModel(__assign(__assign({}, attendeeForm.model), { isLead: false }));
                }
            }
            field.setValue(true);
            _this._isLeadAttendeeChanging = false;
        };
        this.buildEditCustomerButton = function (meetingGuest) {
            return new button_2.default({
                text: "".concat(meetingGuest.rank ? meetingGuest.rank : '', " ").concat(meetingGuest.customerTitle ? meetingGuest.customerTitle : '', " ").concat(meetingGuest.customerName).trim(),
                onClick: function () { _this.showEditCustomer(meetingGuest); }
            });
        };
        this.showEditCustomer = function (meetingGuest) {
            var customerToEdit = {
                customerId: meetingGuest.customerId || meetingGuest.id,
                email: meetingGuest.customerEmail,
                firstName: meetingGuest.firstName,
                lastName: meetingGuest.lastName,
                title: meetingGuest.customerTitle,
                phone: meetingGuest.phone,
                customerCompanyId: meetingGuest.customerCompanyId,
                newCustomerCompanyName: meetingGuest.customerCompany,
                rank: meetingGuest.rank,
            };
            _this.quickAddCustomerForm.open(customerToEdit);
        };
        this.updateCustomer = function (updatedCustomer) {
            var index = _this.meetingGuestDataList.findIndex(function (customer) { return customer.customerId === updatedCustomer.customerId; });
            var customers = __spreadArray([], _this.meetingGuestDataList.getModels(), true);
            if (updatedCustomer.newCustomerCompanyName) {
                customers[index].customerCompany = updatedCustomer.newCustomerCompanyName;
            }
            customers[index].customerCompanyId = updatedCustomer.customerCompanyId;
            customers[index].customerEmail = updatedCustomer.email;
            customers[index].customerName = updatedCustomer.lastName + ', ' + updatedCustomer.firstName;
            customers[index].customerTitle = updatedCustomer.title;
            customers[index].firstName = updatedCustomer.firstName;
            customers[index].lastName = updatedCustomer.lastName;
            customers[index].phone = updatedCustomer.phone;
            customers[index].rank = updatedCustomer.rank;
            _this.meetingGuestDataList.setModels(customers);
            _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.CustomerEditVisibiltyChanged);
        };
        this.getIcon = function (item) {
            if (!item) {
                return null;
            }
            switch (item.form.model.fileType) {
                case 'image/jpeg':
                case 'image/png':
                case 'image/bmp':
                case 'image/jpg':
                case 'image/gif':
                    return icons_1.Icons.Image;
                case 'application/pdf':
                    return icons_1.Icons.Pdf;
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/vnd.ms-word':
                    return icons_1.Icons.Word;
                case 'text/csv':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                case 'application/vnd.ms-excel':
                    return icons_1.Icons.Excel;
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                case 'application/vnd.ms-powerpoint':
                    return icons_1.Icons.PowerPoint;
                default:
                    return icons_1.Icons.File;
            }
        };
        this.buildDownloadButton = function (item) {
            if (!_this.downloadButtons[item.form.model.id]) {
                var buttonText = item.form.model.friendlyName;
                _this.downloadButtons[item.form.model.id] = new button_1.DownloadButton({
                    api: _this.api,
                    text: buttonText,
                    url: _this.api.getMeetingFileDownloadUrl(item.form.model.id)
                });
            }
            return _this.downloadButtons[item.form.model.id];
        };
        this.observationProvider = new utils_1.ObservationProvider();
        this.helpButton = new helpButton_1.default(pages_1.HelpUrlType.MeetingDetail, null, navigator, api);
        this.alert = new __1.Alert();
        this.addAttendeeButton = new button_2.default({
            text: 'Add Attendee',
            onClick: this.addAttendees
        });
        this.addGuestButton = new button_2.default({
            text: 'Add Customer',
            onClick: this.addGuest
        });
        this.closeButton = new button_2.default({
            text: 'Back',
            onClick: this.close
        });
        this.saveButton = new button_2.default({
            text: 'Save',
            onClick: function () {
                _this.closeOnSave = false;
                _this.checkForAttendeeConflicts();
            },
            processingText: 'Saving...'
        });
        this.saveAndCloseButton = new button_2.default({
            text: 'Save & Close',
            onClick: function () {
                _this.closeOnSave = true;
                _this.checkForAttendeeConflicts();
            },
            processingText: 'Saving...'
        });
        this.confirmation = new confirmationPrompt_1.ConfirmationPrompt(true, 'Deactivate Meeting', 'Are you sure you want to deactivate this meeting?', 'Deactivate', 'Cancel', 'Deactivating...');
        this.meetingGuestDataList = new __1.DataList(function () { return [_this.buildIsGuestLeadCheckbox()]; }, false, 'Add Customer', 'No customer guests assigned');
        this.meetingGuestDataList.observationProvider.observeMany(function () {
            _this.setAttendeeCount();
        }, [
            dataList_1.DataListActions.RecordAdded,
            dataList_1.DataListActions.RecordRemoved,
            dataList_1.DataListActions.CancelRecordRemove
        ]);
        this.additionalNotificationRecipientsDataList = new __1.DataList(function () { return []; }, false, 'Add Recipients', 'No additional recipients');
        this._additionalNotificationRecipientAttendeeField = new fields_1.EventAttendeeMultiselectField(this.api, this._eventId, 'eventAttendeeIds', 'Event Attendee');
        this.addAdditionalNotificationRecipientButton = new button_2.default({
            text: 'Add Recipient',
            onClick: function () { return _this.addAdditionalNotificationRecipients(); },
            leftIcon: icons_1.Icons.Plus
        });
        this.confirmation.observationProvider.observe(this.deactivateMeeting, confirmationPrompt_1.ConfirmationPromptActions.Confirmed);
        this.deactivateButton = new button_2.default({
            leftIcon: icons_1.Icons.Trash,
            onClick: this.confirmation.show,
            isVisible: false
        });
        this.duplicateMeetingButton = new button_2.default({
            leftIcon: icons_1.Icons.Duplicate,
            text: 'Duplicate',
            onClick: this.duplicateMeeting,
            isVisible: false
        });
        this.historyButton = new button_2.default({
            leftIcon: icons_1.Icons.Clock,
            onClick: function () { return _this.showHistoryTable(); },
            text: 'History'
        });
        this.guestsArrivedButton = new button_2.default({
            onClick: function () { return _this.guestsArrivedButtonClicked(); },
        });
        this.meetingNotesButton = new button_2.default({
            leftIcon: icons_1.Icons.Report,
            onClick: function () { return _this.showMeetingNotes(); },
            text: 'Notes',
            isVisible: false
        });
        this.notifyButton = new button_2.default({
            leftIcon: icons_1.Icons.Notification,
            text: 'Notify',
            onClick: function () { return _this.showNotificationPane(); },
            isVisible: false
        });
        this.notificationModal = new _1.MeetingNotificationModal(api);
        this.calendarNotificationModal = new meetingCalendarNotificationModal_1.default(api, currentUserManager);
        this.calendarNotificationModal.observationProvider.observe(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.saveComplete()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, _1.MeetingCalendarNotificationModalActions.Closing);
        this.meetingNotesForm = new _1.MeetingNotesForm(api, currentUserManager);
        this.actionsDropdown = new __1.Dropdown([
            { button: this.notifyButton },
            { button: this.historyButton },
            { button: this.duplicateMeetingButton }
        ], 'More');
        this.actionsDropdown.dropdownButton.setRightIcon(null);
        this.actionsDropdown.dropdownButton.setLeftIcon(icons_1.Icons.Hamburger);
        this.removeConflictsButton = new button_2.default({
            text: 'Remove Attendee and Save',
            onClick: this.removeSelectedConflicts,
            isDisabled: true
        });
        this.saveWithConflictsButton = new button_2.default({
            text: 'Save with Conflicts',
            onClick: function () {
                _this.closeConflictModal();
                _this.submit();
            }
        });
        this.quickAddCustomerForm = new customers_1.QuickAddCustomerForm(this.api);
        this.quickAddCustomerForm.observationProvider.observeMany(function () { _this.observationProvider.notifyObservers(_1.MeetingDetailFormActions.CustomerEditVisibiltyChanged); }, [customers_1.QuickAddCustomerFormActions.Opened, customers_1.QuickAddCustomerFormActions.Closed]);
        this.quickAddCustomerForm.observationProvider.observe(this.updateCustomer, customers_1.QuickAddCustomerFormActions.CustomerUpdated);
        this._addFileField = new fields_1.BinaryFileInputField('fieldField', 'Add new file', false, fields_1.FileInputFileTypes.All, 5000000);
        this._addFileField.observationProvider.observe(this.addFile, fields_1.FieldActions.ValueChanged);
        this._fileDataList = new __1.DataList(function () { return [
            new __1.TextField('friendlyName', 'Short Name', false, 250),
            new __1.TextField('description', 'Description', false, 250)
        ]; }, true, '', 'No Meeting Files');
        this._fileDataList.addItemButton.setVisibility(false);
        this.editButton = new button_2.default({
            leftIcon: icons_1.Icons.Edit,
            onClick: this.toggleEditMode,
            isVisible: true,
            text: 'Edit'
        });
        this.cancelEditButton = new button_2.default({
            text: 'Cancel',
            onClick: function () {
                if (_this.form.model.id) {
                    _this.setEditMode(false);
                }
                else {
                    _this.close();
                }
            },
            isVisible: false
        });
        this.meetingInfoButton = new button_2.default({
            leftIcon: icons_1.Icons.Meeting,
            text: 'Meeting Detail',
            onClick: this.showMeetingInfo
        });
        this.actionItemsButton = new button_2.default({
            leftIcon: icons_1.Icons.Checked,
            text: 'Action Items',
            onClick: this.showActionItems,
            isVisible: false
        });
    }
    Object.defineProperty(MeetingDetailForm.prototype, "activePane", {
        get: function () {
            return this._activePane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "isEditMode", {
        get: function () {
            return this._isEditMode;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "title", {
        get: function () {
            return this._title;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "golfCartsEnabled", {
        get: function () {
            return this._golfCartsEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "attendeeForms", {
        get: function () {
            return this._attendeeForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "addFileField", {
        get: function () {
            return this._addFileField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "fileDataList", {
        get: function () {
            return this._fileDataList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "generalInfoFields", {
        get: function () {
            return this._generalInfoFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "attendeeCountFields", {
        get: function () {
            return this._attendeeCountFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "commentFields", {
        get: function () {
            return this._commentFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "preMeetingPickupFields", {
        get: function () {
            return this._preMeetingPickupFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "postMeetingPickupFields", {
        get: function () {
            return this._postMeetingPickupFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "meetingStatusField", {
        get: function () {
            return this._meetingStatusField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "customerSearchField", {
        get: function () {
            return this._customerSearchField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "attendeeTypeDropdown", {
        get: function () {
            return this._attendeeTypeDropdown;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "businessAreaAttendeesDropdown", {
        get: function () {
            return this._businessAreaAttendeesDropdown;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "departmentAttendeesDropdown", {
        get: function () {
            return this._departmentAttendeesDropdown;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "foodNeededField", {
        get: function () {
            return this._foodNeededField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "venueField", {
        get: function () {
            return this._venueField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "eventAttendeeDropdown", {
        get: function () {
            return this._eventAttendeeDropdown;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "additionalNotificationRecipientAttendeeField", {
        get: function () {
            return this._additionalNotificationRecipientAttendeeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "urlField", {
        get: function () {
            return this._urlField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "startTimeField", {
        get: function () {
            return this._startTimeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "endTimeField", {
        get: function () {
            return this._endTimeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "attendeesNotifiedField", {
        get: function () {
            return this._attendeesNotifiedField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "meetingInvitesEnabledField", {
        get: function () {
            return this._meetingInvitesEnabledField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "overrideAttendeeCountCheckbox", {
        get: function () {
            return this._overrideAttendeeCountCheckbox;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "totalAttendeeCountField", {
        get: function () {
            return this._totalAttendeeCountField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "individualAttendeeSelectionVisible", {
        get: function () {
            return this._individualAttendeeSelectionVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "originalMeetingTitle", {
        get: function () {
            return this._originalMeetingTitle;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "historyTable", {
        get: function () {
            return this._historyTable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "attendeeConflictModalOpen", {
        get: function () {
            return this._attendeeConflictModalOpen;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "attendeeConflicts", {
        get: function () {
            return this._attendeeConflicts;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "selectedConflictCheckboxes", {
        get: function () {
            return this._selectedConflictCheckboxes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingDetailForm.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: false,
        configurable: true
    });
    MeetingDetailForm.prototype.setModelAttendeeSelection = function () {
        var model = this._form.model;
        model.attendees = this._attendeeForms.map(function (attendeeForm) { return attendeeForm.model; });
        model.attendeeDepartmentId = null;
        model.attendeeBusinessAreaId = null;
        model.allAttendees = false;
        switch (this._attendeeTypeDropdown.value) {
            case AttendeeSelectionTypes.AllCompanyAttendees:
                model.attendees = [];
                model.allAttendees = true;
                break;
            case AttendeeSelectionTypes.BusinessArea:
                model.attendeeBusinessAreaId = this._businessAreaAttendeesDropdown.value;
                break;
            case AttendeeSelectionTypes.Department:
                model.attendeeDepartmentId = this._departmentAttendeesDropdown.value;
                break;
        }
    };
    MeetingDetailForm.prototype.setFormToDefaultMeeting = function () {
        this._attendeesNotifiedField.setVisibility(false);
        this._fileDataList.setModels([]);
        this.form.setModel({
            eventId: this._eventId,
            totalAttendees: 0,
            status: this._eventManager.defaultMeetingStatus,
            meetingInvitesEnabled: this._eventManager.isMeetingInvitesEnabled,
        });
        this._meetingCategoryField.setDefaultValue();
    };
    return MeetingDetailForm;
}());
exports.default = MeetingDetailForm;
var AttendeeSelectionTypes;
(function (AttendeeSelectionTypes) {
    AttendeeSelectionTypes[AttendeeSelectionTypes["IndividualAttendees"] = 1] = "IndividualAttendees";
    AttendeeSelectionTypes[AttendeeSelectionTypes["AllCompanyAttendees"] = 2] = "AllCompanyAttendees";
    AttendeeSelectionTypes[AttendeeSelectionTypes["BusinessArea"] = 3] = "BusinessArea";
    AttendeeSelectionTypes[AttendeeSelectionTypes["Department"] = 4] = "Department";
})(AttendeeSelectionTypes || (AttendeeSelectionTypes = {}));
