"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseField_1 = require("./baseField");
var button_1 = require("../button/button");
var icons_1 = require("../icons");
var fieldActions_1 = require("./fieldActions");
var BinaryFileInputField = /** @class */ (function (_super) {
    __extends(BinaryFileInputField, _super);
    function BinaryFileInputField(key, label, isRequired, acceptedFileTypes, maxFileBytes, requiredValidationMessage) {
        if (acceptedFileTypes === void 0) { acceptedFileTypes = _1.FileInputFileTypes.All; }
        if (maxFileBytes === void 0) { maxFileBytes = 307200; }
        var _this = _super.call(this, _1.FieldType.BinaryFile, key, label, isRequired, null, requiredValidationMessage) || this;
        _this.key = key;
        _this.acceptedFileTypes = acceptedFileTypes;
        _this.maxFileBytes = maxFileBytes;
        _this.removeButton = new button_1.default({
            onClick: function () { return _this.clearValue(); },
            leftIcon: icons_1.Icons.Minus,
            isVisible: false
        });
        return _this;
    }
    Object.defineProperty(BinaryFileInputField.prototype, "placeHolderText", {
        get: function () {
            return this._placeHolderText;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BinaryFileInputField.prototype, "value", {
        get: function () {
            return this._binaryFileValue;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BinaryFileInputField.prototype, "maxFileSizeKb", {
        get: function () {
            return this.maxFileBytes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BinaryFileInputField.prototype, "maxFileSizeMb", {
        get: function () {
            return this.maxFileSizeKb / 1000;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BinaryFileInputField.prototype, "maxFileSizeGb", {
        get: function () {
            return this.maxFileSizeMb / 1000;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BinaryFileInputField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isFileSizeValid()) {
                if (this.maxFileSizeMb >= 1000) {
                    return "File size cannot exceed ".concat(this.maxFileSizeGb, " Gb");
                }
                if (this.maxFileSizeKb >= 1000) {
                    return "File size cannot exceed ".concat(this.maxFileSizeMb, " Mb");
                }
                return "File size cannot exceed ".concat(this.maxFileSizeKb, " Kb");
            }
            if (this.value && !this.value.fileType) {
                return 'File must be one of the following types: Images, PDF, Word, Excel, or PowerPoint';
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    BinaryFileInputField.prototype.isValid = function () {
        var _a;
        var isValid = this.checkRequiredStatus();
        isValid = isValid && this.isFileSizeValid();
        isValid = isValid && !!((_a = this.value) === null || _a === void 0 ? void 0 : _a.fileType);
        this.setIsValid(isValid);
        return isValid;
    };
    BinaryFileInputField.prototype.isFileSizeValid = function () {
        if (!this.value) {
            return true;
        }
        return this.value.fileSize <= this.maxFileBytes;
    };
    BinaryFileInputField.prototype.clear = function () {
        this.setValue(null);
    };
    BinaryFileInputField.prototype.setValue = function (newValue) {
        if (newValue) {
            this.removeButton.setVisibility(true);
        }
        else {
            this.removeButton.setVisibility(false);
        }
        this._binaryFileValue = newValue;
        if (this.isFileSizeValid() === false) {
            this.setIsValid(false);
        }
        this.observationProvider.notifyObservers(fieldActions_1.default.ValueChanged);
    };
    return BinaryFileInputField;
}(baseField_1.default));
exports.default = BinaryFileInputField;
