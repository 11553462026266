"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var MultipleEmailField = /** @class */ (function (_super) {
    __extends(MultipleEmailField, _super);
    function MultipleEmailField(key, isRequired, label, initialValue) {
        var _this = _super.call(this, _1.FieldType.MultipleEmailField, key, label ? label : 'Email Address(es)', isRequired, 250, 'Required') || this;
        _this.key = key;
        _this.setValue = function (emails) {
            _this._emailValues = emails;
            _super.prototype.setStringValue.call(_this, emails.join('; '));
            _this.observationProvider.notifyObservers(_1.FieldActions.ValueChanged);
        };
        _this._emailValues = [];
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    Object.defineProperty(MultipleEmailField.prototype, "emailValues", {
        get: function () {
            return this._emailValues;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MultipleEmailField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isValidEmail() && this.isDirty) {
                return 'Email is invalid';
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    MultipleEmailField.prototype.isValidEmail = function () {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (var i = 0; i < this._emailValues.length; i++) {
            if (!regex.test(this._emailValues[i].toString())) {
                return false;
            }
        }
        return true;
    };
    MultipleEmailField.prototype.clearValue = function () {
        this._emailValues = [];
        this.observationProvider.notifyObservers(_1.FieldActions.ValueChanged);
    };
    MultipleEmailField.prototype.setStringValue = function (stringValue) {
        stringValue = stringValue.replace(/\s+/g, '');
        stringValue = stringValue.replace(/,/g, ';');
        stringValue = stringValue.replace(/;/g, '; ');
        this._emailValues = stringValue.split('; ');
        _super.prototype.setStringValue.call(this, stringValue);
    };
    MultipleEmailField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.isValidEmail()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    Object.defineProperty(MultipleEmailField.prototype, "value", {
        get: function () {
            return this._emailValues;
        },
        enumerable: false,
        configurable: true
    });
    return MultipleEmailField;
}(_1.BaseField));
exports.default = MultipleEmailField;
